import React from "react";
import { graphql } from "gatsby";
import { get } from "lodash";
// import contentParser from "gatsby-wpgraphql-inline-images";

import ReactHtmlParser from "react-html-parser";
import parse from "html-react-parser";

import SEO from "../components/MetaSeo";
import MiniHero from "../components/MiniHero";
import Layout from "../components/layout";
import SearchCourse from "../components/SearchCourse";
import WhatsappButton from "../components/WhatsappButton";

const Page = ({
  data: {
    wpPage: { title, content, slug, featuredImage }
  }
}) => {
  const wordPressUrl = process.env.GATSBY_WP_URL;
  const uploadsUrl = `${process.env.GATSBY_WP_URL}/wp-content/uploads/`;

  return (
    <Layout>
      <SEO title={title} pathname={slug} />
      <MiniHero name={title} img={get(featuredImage, "node.localFile")} />

      {/* <SearchCourse
        className="relative bg-transparent"
        style={{ top: "-5rem" }}
      /> */}

      <div className="container mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4">
          <section className="col-span-4 xl:p-20">
            {/* <div
              className="entry-content"
              key={`body`}
              id="___gatsby"
              dangerouslySetInnerHTML={{
                __html: content
              }}
            /> */}

            {/* <div className="entry-content">
              {contentParser({ content }, { wordPressUrl, uploadsUrl })}
            </div> */}

            {content && <div className="entry-content">{parse(content)}</div>}
          </section>
        </div>
      </div>

      <WhatsappButton />
    </Layout>
  );
};

export default Page;

export const pageQuery = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      slug
      featuredImage {
        node {
          sourceUrl
          localFile {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1800) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`;
